/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region Fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansBold {
  font-weight: 700 !important;
  font-family: 'Open Sans', sans-serif;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
}
.LondrindaSolidBlack {
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 900 !important;
}
@font-face {
  font-family: 'Hvd_comic_serif_pro';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.svg#hvd_comic_serif_pro') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.HvdComicSerifPro {
  font-family: 'Hvd_comic_serif_pro', sans-serif;
  font-weight: normal !important;
  font-display: swap;
}
body,
html {
  font-size: 16px;
}
/*#endregion Fonts*/
.textContent h1,
.lower-content h2 {
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 900 !important;
  font-size: 2.6rem;
  color: #ff5400;
  text-shadow: none;
  margin-top: 0;
}
.textContent {
  padding: 0;
}
@media (max-width: 767px) {
  .textContent {
    text-align: center;
  }
}
.nicefont,
h1,
h2,
h3 {
  font-weight: 700 !important;
  font-family: 'Open Sans', sans-serif;
}
form {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: #33abff;
  position: relative;
}
body:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/geometric-pattern-10.png");
  mix-blend-mode: luminosity;
  opacity: 0.1;
}
.outer-wrapper {
  overflow: hidden;
  max-width: 100vw;
  position: relative;
  text-align: center;
}
.outer-wrapper.outer-wrapper--alt .columns__main {
  background: #fff;
}
.outer-wrapper.outer-wrapper--alt .columns__main:before {
  display: none;
}
.outer-wrapper.outer-wrapper--alt .textContent {
  padding-top: 20px;
}
.outer-wrapper.outer-wrapper--alt .curve {
  display: none;
}
.outer-wrapper.outer-wrapper--alt .homepage-columns__left {
  width: 100%;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    margin: 0 auto 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0;
    margin: 0 auto 0;
  }
}
@media (min-width: 1260px) {
  .container {
    width: 1230px;
    padding: 0;
    margin: 0 auto 0;
  }
}
.container__border {
  display: inline-block;
  position: relative;
  padding: 0 10px;
}
@media (max-width: 767px) {
  .container__border {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .container__border {
    padding: 0 20px;
    -webkit-box-shadow: 0px 0px 60px 0px rgba(49, 59, 73, 0.5);
            box-shadow: 0px 0px 60px 0px rgba(49, 59, 73, 0.5);
  }
}
.container__border:before,
.container__border:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  background-size: 100% auto;
}
@media (min-width: 992px) {
  .container__border:before,
  .container__border:after {
    width: 20px;
  }
}
@media (max-width: 767px) {
  .container__border:before,
  .container__border:after {
    display: none;
  }
}
.container__border:before {
  left: 0;
  background-color: #006aff;
  background-image: -webkit-linear-gradient(left, #006aff, #0062eb);
  background: -webkit-gradient(linear, left top, right top, from(#006aff), to(#0062eb));
  background: linear-gradient(to right, #006aff, #0062eb);
}
.container__border:after {
  right: 0;
  background-color: #0062eb;
  background-image: -webkit-linear-gradient(left, #0062eb, #006aff);
  background: -webkit-gradient(linear, left top, right top, from(#0062eb), to(#006aff));
  background: linear-gradient(to right, #0062eb, #006aff);
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
  padding-top: 53px;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #ec2028;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  min-height: 5px;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: block !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.drop-shadow {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
}
.header {
  text-align: center;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
@media (max-width: 767px) {
  .header {
    padding: 30px 15px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 16.83%;
  }
}
.header__bg {
  background-color: #0091e8;
  background: -webkit-gradient(linear, left top, left bottom, from(#0091e8), to(#43d3f6));
  background: linear-gradient(to bottom, #0091e8, #43d3f6);
  position: relative;
  padding: 0 15px 1px;
  overflow: hidden;
}
.header__bg:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/001/starburst.svg");
  background-repeat: repeat;
  opacity: 0.2;
  -webkit-transform: scale(1.6);
          transform: scale(1.6);
}
.header__logo-xs {
  max-width: 90% !important;
  display: block;
  margin: 10px auto 0;
}
@media (max-width: 767px) {
  .header__follow {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__follow {
    position: absolute;
    width: 20.65%;
    bottom: 69.33%;
    left: 36.94%;
  }
}
@media (max-width: 767px) {
  .header__follow {
    margin: 10px auto 0;
  }
}
@media (max-width: 767px) {
  .header__social {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__social {
    position: absolute;
    width: 28.66%;
    bottom: 21.73%;
    left: 32.9%;
  }
}
@media (max-width: 767px) {
  .header__social {
    position: relative;
    margin: 0 auto 10px;
  }
}
.header #SocialMediaLinks {
  text-align: center;
}
@media (max-width: 767px) {
  .header #SocialMediaLinks {
    text-align: center;
    margin: 0 auto 10px;
    padding: 0 30px;
  }
}
.header #SocialMediaLinks a {
  display: inline-block;
  max-width: 20%;
  margin: 2%;
}
@media (max-width: 767px) {
  .header #SocialMediaLinks a {
    max-width: 15%;
  }
}
.header #SocialMediaLinks img {
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
@media (max-width: 767px) {
  .header__call {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 30.22%;
    bottom: 69.33%;
    right: 3.17%;
  }
}
@media (max-width: 767px) {
  .header__call {
    position: relative;
    display: block;
    margin: 20px auto 0;
  }
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 32.53%;
    bottom: 35.46%;
    right: 2.04%;
  }
}
@media (max-width: 767px) {
  .header__phone {
    position: relative;
    display: block;
    margin: 0 auto 10px;
  }
}
@media (max-width: 767px) {
  .header__email {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__email {
    position: absolute;
    width: 30.32%;
    bottom: 14.38%;
    right: 3.12%;
  }
}
@media (max-width: 767px) {
  .header__email {
    position: relative;
    display: block;
    margin: 10px auto 0;
  }
}
.header__weather-link {
  border-radius: 15px;
  background-color: #5fe0f4;
  background: -webkit-gradient(linear, left top, left bottom, from(#5fe0f4), to(#0079d0));
  background: linear-gradient(to bottom, #5fe0f4, #0079d0);
}
@media (max-width: 767px) {
  .header__weather-link {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__weather-link {
    position: absolute;
    width: 24.73%;
    bottom: 13.42%;
    left: 3.33%;
  }
}
@media (max-width: 767px) {
  .header__weather-link {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80%;
    padding: 5px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    max-width: 270px !important;
  }
}
@media (min-width: 768px) {
  .header__weather-link {
    height: 63.9%;
  }
}
.header__weather-link:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  bottom: 3px;
  right: 3px;
  border-radius: 13px;
  background-color: #43cbed;
  background: -webkit-gradient(linear, left top, left bottom, from(#43cbed), to(#009bdc));
  background: linear-gradient(to bottom, #43cbed, #009bdc);
}
.header__weather-link:hover .header__weather-sun {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.header__weather-sun {
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}
@media (max-width: 767px) {
  .header__weather-sun {
    -ms-flex-item-align: center;
        align-self: center;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .header__weather-sun {
    position: absolute;
    width: 44%;
    left: -4%;
    top: -6%;
  }
}
@media (max-width: 767px) {
  .header__weather-text {
    -ms-flex-item-align: center;
        align-self: center;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .header__weather-text {
    position: absolute;
    width: 51%;
    left: 42%;
    top: 13%;
  }
}
.header__button {
  background: -webkit-gradient(linear, left top, left bottom, from(#c7e800), to(#329c00));
  background: linear-gradient(to bottom, #c7e800, #329c00);
  color: #FFF;
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
  padding: 10px 30px;
  font-size: 2rem;
  border-radius: 15px;
  text-shadow: -1px 4px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  display: block;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__button:hover,
.header__button.active,
.header__button:focus {
  color: #FFF;
}
.header__button-wrapper {
  margin: 20px auto;
}
.banner {
  background-color: #fff;
  position: relative;
  border: 6px solid #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0 23px 15px -10px rgba(0, 0, 0, 0.3);
          box-shadow: 0 23px 15px -10px rgba(0, 0, 0, 0.3);
}
.banner #CarouselContainer {
  padding-top: 55.18%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.widgets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  padding: 0 15px;
  margin-bottom: 170px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 1200px) {
  .widgets {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.widgets__outer {
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;
}
.widgets__outer:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -15px;
  right: -15px;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/widgets-bg.png");
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.widgets__area {
  background-color: #c7e800;
  background: -webkit-gradient(linear, left top, left bottom, from(#c7e800), to(#329c00));
  background: linear-gradient(to bottom, #c7e800, #329c00);
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .widgets__area {
    width: 100%;
    max-width: 300px;
  }
}
@media (min-width: 768px) {
  .widgets__area {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .widgets__area {
    width: 31%;
    margin-bottom: 0;
  }
}
.widgets__area:before {
  content: "";
  width: 100%;
  display: block;
  padding-top: 66.44%;
}
@media (min-width: 992px) {
  .widgets__area:before {
    padding-top: 44.44%;
  }
}
.widgets__area:after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border-radius: 13px;
  background-color: #a2d900;
  background: -webkit-gradient(linear, left top, left bottom, from(#a2d900), to(#4bb400));
  background: linear-gradient(to bottom, #a2d900, #4bb400);
}
.widgets__area-text {
  position: absolute;
  width: 63.79%;
  left: 21.3%;
  z-index: 1;
  top: 9.92%;
}
@media (min-width: 992px) {
  .widgets__area-text {
    top: 12.92%;
  }
}
.widgets__area-icon {
  position: absolute;
  width: 40%;
  left: -10%;
  top: 8%;
  z-index: 1;
}
.widgets #PostcodeSearchLeft {
  position: absolute;
  height: unset;
  top: 31.5%;
  width: 85.19%;
  left: 6.67%;
  z-index: 1;
}
@media (min-width: 992px) {
  .widgets #PostcodeSearchLeft {
    height: 34.17%;
    top: 47.5%;
  }
}
.widgets #PostcodeSearchLeft .form-control {
  height: 45px;
  padding-left: 27%;
  border: none;
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}
.widgets #PostcodeSearchLeft .form-control::-webkit-input-placeholder {
  color: #757575;
}
.widgets #PostcodeSearchLeft .form-control::-moz-placeholder {
  color: #757575;
}
.widgets #PostcodeSearchLeft .form-control::-ms-input-placeholder {
  color: #757575;
}
.widgets #PostcodeSearchLeft .form-control::placeholder {
  color: #757575;
}
@media (min-width: 992px) {
  .widgets #PostcodeSearchLeft .form-control {
    height: 100%;
  }
}
.widgets #PostcodeSearchRight {
  position: absolute;
  height: unset;
  top: 64.5%;
  left: 0;
  right: 0;
  text-align: center;
}
@media (min-width: 992px) {
  .widgets #PostcodeSearchRight {
    display: none;
  }
}
.widgets #PostcodeSearchRight .AreaSearchButton {
  z-index: 2;
  background: -webkit-gradient(linear, left top, left bottom, from(#5fe0f4), to(#0079d0));
  background: linear-gradient(to bottom, #5fe0f4, #0079d0);
  color: #FFF;
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
  width: 60%;
  padding: 5px 30px;
  font-size: 1.75rem;
  border-radius: 15px;
  text-shadow: -1px 4px 0 rgba(0, 0, 0, 0.3);
  position: relative;
}
.widgets #PostcodeSearchRight .AreaSearchButton:hover,
.widgets #PostcodeSearchRight .AreaSearchButton.active,
.widgets #PostcodeSearchRight .AreaSearchButton:focus {
  color: #FFF;
}
.widgets__availability {
  background-color: #c7e800;
  background: -webkit-gradient(linear, left top, left bottom, from(#c7e800), to(#329c00));
  background: linear-gradient(to bottom, #c7e800, #329c00);
  border-radius: 15px;
  position: relative;
  width: 96%;
}
@media (max-width: 767px) {
  .widgets__availability {
    max-width: 300px;
    padding: 15px;
  }
}
@media (min-width: 1200px) {
  .widgets__availability {
    width: 67.74%;
  }
}
.widgets__availability:before {
  content: "";
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .widgets__availability:before {
    padding-top: 20.34%;
  }
}
.widgets__availability:after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border-radius: 13px;
  background-color: #a2d900;
  background: -webkit-gradient(linear, left top, left bottom, from(#a2d900), to(#4bb400));
  background: linear-gradient(to bottom, #a2d900, #4bb400);
}
.widgets__availability-text {
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  .widgets__availability-text {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .widgets__availability-text {
    position: absolute;
    width: 72.98%;
    left: 12.12%;
    top: 12.92%;
  }
}
.widgets .SearchDate {
  left: 3.05%;
  width: 33.9%;
  border: none;
  border-radius: 8px;
  text-align: center;
  color: #757575;
}
.widgets .SearchDate::-webkit-input-placeholder {
  color: #757575;
}
.widgets .SearchDate::-moz-placeholder {
  color: #757575;
}
.widgets .SearchDate::-ms-input-placeholder {
  color: #757575;
}
.widgets .SearchDate::placeholder {
  color: #757575;
}
.widgets .SearchCategory {
  left: 38.64%;
  width: 33.9%;
  border: none;
  border-radius: 8px;
  text-align: center;
  color: #757575;
}
.widgets .SearchCategory::-webkit-input-placeholder {
  color: #757575;
}
.widgets .SearchCategory::-moz-placeholder {
  color: #757575;
}
.widgets .SearchCategory::-ms-input-placeholder {
  color: #757575;
}
.widgets .SearchCategory::placeholder {
  color: #757575;
}
.widgets .SearchButton {
  left: 74.24%;
  width: 23.73%;
  border: none;
  border-radius: 8px;
  position: relative;
  z-index: 2;
  background: -webkit-gradient(linear, left top, left bottom, from(#5fe0f4), to(#0079d0));
  background: linear-gradient(to bottom, #5fe0f4, #0079d0);
  color: #FFF;
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
  padding: 0 5px 5px;
  font-size: 1.75rem;
  border-radius: 15px;
  text-shadow: -1px 4px 0 rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .widgets .SearchButton {
    display: block;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .widgets .SearchButton {
    font-size: 1.5rem;
  }
}
.widgets .SearchButton:hover,
.widgets .SearchButton.active,
.widgets .SearchButton:focus {
  color: #FFF;
}
.widgets .SearchDate,
.widgets .SearchCategory,
.widgets .SearchButton {
  position: absolute;
  height: 34.17%;
  top: 47.5%;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
@media (max-width: 767px) {
  .widgets .SearchDate,
  .widgets .SearchCategory,
  .widgets .SearchButton {
    position: relative;
    width: 80%;
    height: 40px;
    left: auto;
    top: auto;
    margin-bottom: 15px;
  }
}
.featured-cats {
  position: relative;
  text-align: center;
  background: #fff;
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.featured-cats__inner {
  margin-top: -100px;
}
.featured-cats__inner > div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.featured-cats__title {
  text-align: center;
  text-transform: uppercase;
  color: #5a5a5a;
  font-family: 'Hvd_comic_serif_pro', sans-serif;
  font-weight: normal !important;
  font-display: swap;
  font-size: 1.2rem;
}
.featured-cats--web-starter {
  margin-top: 120px;
}
.featured-cats--multiple #CarouselContainer {
  padding-top: 100%;
  overflow: hidden;
}
.featured-cats--multiple #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.featured-cats--multiple #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.featured-cats--multiple #CarouselContainer .carousel-inner .item a img,
.featured-cats--multiple #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.featured-cats--multiple .item {
  height: 100%;
}
.featured-cats--multiple a,
.featured-cats--multiple img {
  width: 100%;
  height: 100% !important;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.homepage-columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.homepage-columns__left {
  width: 100%;
  background: #fff;
}
@media (min-width: 1200px) {
  .homepage-columns__left {
    width: 66.67%;
  }
}
.homepage-columns__right {
  background: #fff;
  padding: 0 15px;
  width: 100%;
}
@media (min-width: 1200px) {
  .homepage-columns__right {
    width: 33.33%;
  }
}
.search-box {
  background: #fff;
  border-radius: 8px;
  width: calc(100% - 10px);
}
.search-box .input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.search-box .input-group .input-group-btn .btn-default {
  width: 40px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border: none;
  color: #444 !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.search-box .input-group .input-group-btn .btn-default:hover {
  color: #000 !important;
  background: #fff !important;
}
.search-box .input-group input {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border: none;
  width: calc(100% - 40px);
  height: 40px;
}
.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 15px;
  position: relative;
}
.columns__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.columns__left {
  position: relative;
  width: 200px;
  background: #f2f3ff;
  padding: 0 15px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHkAAAA9CAMAAAC+7TYBAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAAhFBMVEWjy1Sw21er1VakzFOx3FelzVSmzlSkzFSjy1Oky1Sp0lWv2leq01Wt11as1lamz1Sr1Faky1Ow2lelzFSo0VWnz1Sq1FWs1Vamz1Wo0FSn0FWu2Fao0FWn0FSu2Venz1Wu2Vav2Vaq1Fat1lalzlSr1VWp0VWt2Fav2Vex21er1FWp01WbV8XYAAACx0lEQVRYw8XZ13ajMBAGYIFQoVf3FpckW97//VYyBgORjIp1dq5sx1+GA9LAfwwAACEhBOF8gRFiL5LKC6pjgnjlh9MaUfCibOxD55HvF8XyUnx7QeBV6eenzytaIEod2buGt4UfMzYpLy7mOxvb9rhrtEmuE18ds+SACHVlW11SWEdpPNLHrIGlwlGb2laHtIS4Waa/evuRJRvMMHVmHxqw71Ecpf1lWm0RdWs7zXmN/d+dLthVmsc2ttecg6RbKF6ic9RGdqgR9u/7gf8LH9danfXtULfXKt7xdZpGWKuzvh3qTXYOzumfZnk9s/W50eqsb4c6Yucq/cIUN1d23iKtzvr2uTMIWla7VYQhhTha7aolX6EO7VOj7crzF/edSFHue/4JKXc2sUMdXfYIMkEpRPvia63TWd8O9An354h7rNPZwD7nEEF8+ne6ZDde4tQ+1ycYTR6qNf9M7FgPPpbo8cc2dqCVStLZwOrq8RW0sZo6RGv0JqulaUj2xZZto3dYHc2n5CVO2PMPfYPV03wyFoOZbGM19T4LVhiZdR5bttAVNO0q/5sGWXPr35tbtc49pgd/F6RJrtFZZgHiUWxOE/atmg1mCKPvmD1xNBDyPGVlAU98c5q+SIvGFvDEN6P5qpSmRWMLWOK7zWtpWjS3gCW+evaMEWlaNLeAJb5SYX3K0qK5BaXazpClRXPL/qA0h2Rp0dzyO4fSBJSkRRurOnvFadHGqmtRWrSx6lqUFm2suhalRRurrkVp0cIq7wxxWjS36p3FadHcgvumDBW2pCQtGlugFoCM06Lc8mMm9weLuapvPC4+3pSkZjd7KwtI+1hDVAoKXhtbrc6iMrbCztCis7IVXGee90PVMrcqiU+jdO3wCASJz5kdzSFR4nNlp/pn4nNlf+hJWnRnu98iZGnRnZ3qaVqkzmyrX6RFZ/Z//S75DxVlePHxQA4LAAAAAElFTkSuQmCC');
}
@media (min-width: 992px) {
  .columns__left {
    width: 300px;
  }
}
.columns__main {
  position: relative;
  background: #1ad9ff;
}
@media (min-width: 768px) {
  .columns__main {
    padding: 0 15px;
    width: calc(100% - 200px);
  }
}
@media (min-width: 992px) {
  .columns__main {
    width: calc(100% - 300px);
  }
}
.columns__main:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  opacity: 0.05;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/main-bg-pattern.png");
}
.columns__main--offset {
  position: relative;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .columns__main--offset {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.columns .pageContent {
  background: #fff;
}
.toplinks,
.category-links {
  text-align: left;
}
.toplinks__ul,
.category-links__ul {
  display: contents;
}
.toplinks__li,
.category-links__li {
  list-style-type: none;
  position: relative;
}
.toplinks__link,
.category-links__link,
.toplinks__child-link,
.category-links__child-link {
  color: #fff;
  background: none;
  font-size: 1.2rem;
  display: block;
  padding: 10px 10px 10px 20px;
  margin-top: 10px;
  position: relative;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  text-shadow: 1px 1px 2px #000;
  margin-left: -25px;
  background-size: 100% 100%;
}
.toplinks__link.active,
.category-links__link.active,
.toplinks__child-link.active,
.category-links__child-link.active,
.toplinks__link:focus,
.category-links__link:focus,
.toplinks__child-link:focus,
.category-links__child-link:focus {
  color: #fff;
}
.toplinks__link:before,
.category-links__link:before,
.toplinks__child-link:before,
.category-links__child-link:before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 10px solid #fff;
  border-bottom: 5px solid transparent;
  left: 10px;
  opacity: 0;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
}
.toplinks__link:after,
.category-links__link:after,
.toplinks__child-link:after,
.category-links__child-link:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 5px solid #000;
  border-right: 5px solid #000;
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.toplinks__link:hover,
.category-links__link:hover,
.toplinks__child-link:hover,
.category-links__child-link:hover,
.toplinks__link.active,
.category-links__link.active,
.toplinks__child-link.active,
.category-links__child-link.active {
  padding-left: 30px;
  color: #fff;
}
.toplinks__link:hover:before,
.category-links__link:hover:before,
.toplinks__child-link:hover:before,
.category-links__child-link:hover:before,
.toplinks__link.active:before,
.category-links__link.active:before,
.toplinks__child-link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
  -webkit-transition: all 0.3s 0.15s;
  transition: all 0.3s 0.15s;
}
.toplinks__child-link,
.category-links__child-link {
  margin-left: 0;
}
.toplinks__child-link:after,
.category-links__child-link:after {
  display: none;
}
.toplinks__dropdown,
.category-links__dropdown {
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#40C1FF), to(#2495FE));
  background: linear-gradient(to bottom, #40C1FF, #2495FE);
  margin-left: -25px;
}
.toplinks__dropdown-link,
.category-links__dropdown-link {
  text-shadow: 1px 1px 2px #000;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  padding: 10px 10px 10px 20px;
  font-size: 1.2rem;
  color: #fff !important;
  white-space: normal !important;
}
.toplinks__dropdown-li:nth-child(5n+1) .toplinks__dropdown-link:hover,
.category-links__dropdown-li:nth-child(5n+1) .toplinks__dropdown-link:hover {
  background-color: #F1A639;
  background: -webkit-gradient(linear, left top, left bottom, from(#F1A639), to(#E66921));
  background: linear-gradient(to bottom, #F1A639, #E66921);
}
.toplinks__dropdown-li:nth-child(5n+2) .toplinks__dropdown-link:hover,
.category-links__dropdown-li:nth-child(5n+2) .toplinks__dropdown-link:hover {
  background-color: #F84BA8;
  background: -webkit-gradient(linear, left top, left bottom, from(#F84BA8), to(#EE2F69));
  background: linear-gradient(to bottom, #F84BA8, #EE2F69);
}
.toplinks__dropdown-li:nth-child(5n+3) .toplinks__dropdown-link:hover,
.category-links__dropdown-li:nth-child(5n+3) .toplinks__dropdown-link:hover {
  background-color: #C573C5;
  background: -webkit-gradient(linear, left top, left bottom, from(#C573C5), to(#9B479E));
  background: linear-gradient(to bottom, #C573C5, #9B479E);
}
.toplinks__dropdown-li:nth-child(5n+4) .toplinks__dropdown-link:hover,
.category-links__dropdown-li:nth-child(5n+4) .toplinks__dropdown-link:hover {
  background-color: #5FCF5C;
  background: -webkit-gradient(linear, left top, left bottom, from(#5FCF5C), to(#3CAE3A));
  background: linear-gradient(to bottom, #5FCF5C, #3CAE3A);
}
.toplinks__dropdown-li:nth-child(5n+5) .toplinks__dropdown-link:hover,
.category-links__dropdown-li:nth-child(5n+5) .toplinks__dropdown-link:hover {
  background-color: #FFD001;
  background: -webkit-gradient(linear, left top, left bottom, from(#FFD001), to(#FFB202));
  background: linear-gradient(to bottom, #FFD001, #FFB202);
}
.toplinks__link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-blue.svg");
}
.toplinks__link:after {
  border-top: 5px solid #175a99;
  border-right: 5px solid #175a99;
}
.category-links__link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-orange.svg");
}
.category-links__link:after {
  border-top: 5px solid #a14516;
  border-right: 5px solid #a14516;
}
.category-links__link + .category-links__child-links .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-orange.svg");
}
.category-links__link:nth-of-type(5n +2) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-pink.svg");
}
.category-links__link:nth-of-type(5n +2):after {
  border-top: 5px solid #a61d45;
  border-right: 5px solid #a61d45;
}
.category-links__link:nth-of-type(5n +2) + .category-links__child-links .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-pink.svg");
}
.category-links__link:nth-of-type(5n +3) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-purple.svg");
}
.category-links__link:nth-of-type(5n +3):after {
  border-top: 5px solid #692f6b;
  border-right: 5px solid #692f6b;
}
.category-links__link:nth-of-type(5n +3) + .category-links__child-links .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-purple.svg");
}
.category-links__link:nth-of-type(5n +4) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-green.svg");
}
.category-links__link:nth-of-type(5n +4):after {
  border-top: 5px solid #267725;
  border-right: 5px solid #267725;
}
.category-links__link:nth-of-type(5n +4) + .category-links__child-links .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-green.svg");
}
.category-links__link:nth-of-type(5n +5) {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-yellow.svg");
}
.category-links__link:nth-of-type(5n +5):after {
  border-top: 5px solid #b27900;
  border-right: 5px solid #b27900;
}
.category-links__link:nth-of-type(5n +5) + .category-links__child-links .category-links__child-link {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/gradient-button-yellow.svg");
}
.left-social-intro {
  max-width: 247px !important;
}
.testimonials__title {
  max-width: 214px !important;
}
@media (min-width: 768px) {
  .testimonials__panels {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .testimonials__panels {
    display: block;
  }
}
.testimonials__panel {
  padding: 5px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 0 0 1px #fff, inset 0 0 0 3px #fff, inset 0 0 0 6px #9dd700;
          box-shadow: 0 0 0 1px #fff, inset 0 0 0 3px #fff, inset 0 0 0 6px #9dd700;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
}
@media (min-width: 768px) {
  .testimonials__panel {
    width: 48%;
  }
}
@media (min-width: 1200px) {
  .testimonials__panel {
    width: 100%;
  }
}
.testimonials__carousel-inner {
  border-radius: 10px;
}
.testimonials__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.testimonials__item {
  padding: 15px;
}
.testimonials__text {
  color: #808080;
}
.testimonials__author {
  color: #ff5500;
  font-size: 1.2rem;
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 900 !important;
}
/*#region Other Left Column Stuff*/
.logo-top,
.logo-bottom {
  display: block;
  width: calc(100% + 30px);
  position: relative;
  left: -15px;
  background-size: 100% 100%;
}
.logo-top__logo,
.logo-bottom__logo {
  z-index: 1;
  margin: 0 auto;
}
.logo-top {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/logo-top-bg.svg");
}
.logo-top__logo {
  display: none;
}
.logo-bottom {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/logo-bottom-bg.svg");
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.logo-bottom__spacer {
  padding-top: 220%;
}
.left-logos {
  max-width: 269px;
  position: relative;
}
.left-logos:before {
  content: "";
  display: block;
  padding-top: 27.5093%;
}
.left-logos img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#SocialMediaLinks {
  text-align: center;
}
@media (min-width: 768px) {
  #SocialMediaLinks {
    text-align: right;
  }
}
#SocialMediaLinks a {
  display: inline-block;
  max-width: 40%;
  margin: 2%;
}
/*#endregion Other Left Column Stuff*/
.curve {
  position: relative;
  margin: -20px 0 0;
}
@media (min-width: 768px) {
  .curve {
    max-width: none !important;
    width: calc(100% + 30px);
    left: -15px;
  }
}
.pageContent {
  padding: 20px;
}
@media (max-width: 767px) {
  .pageContent {
    padding: 10px;
  }
}
/*#region Castle Panels*/
.page-listings {
  background: transparent !important;
  padding: 0 15px;
}
@media (max-width: 767px) {
  .page-listings {
    padding: 0 10px;
  }
}
.page-listings__intro {
  position: relative;
  max-width: 524px;
  margin: 0 auto;
}
.page-listings__intro:before {
  content: "";
  display: block;
  padding-top: 10.8779%;
}
.page-listings__intro img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-listings .row-less-padding {
  margin-left: -8px !important;
  margin-right: -8px !important;
}
.page-listings .row-less-padding > div {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
@media (max-width: 420px) {
  .page-listings .row-less-padding > div {
    width: 100%;
  }
}
.category-title {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: -webkit-gradient(linear, left top, left bottom, from(#bee400), color-stop(40%, #bee400), color-stop(60%, #88ce00), to(#88ce00));
  background: linear-gradient(to bottom, #bee400 0%, #bee400 40%, #88ce00 60%, #88ce00 100%);
  padding: 15px;
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 900 !important;
  position: relative;
}
.castlePanel {
  width: 100%;
  border: none;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  margin: 0 0 30px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .castlePanel {
    text-align: center;
  }
}
.castlePanel:hover {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel:hover .castleCheckBook {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel .castleTitlePanel {
  margin-top: 10px;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  color: #404040;
  font-weight: 700 !important;
  font-family: 'Open Sans', sans-serif;
  position: relative;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #404040;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 700 !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  color: #ff0000;
  font-size: 0.8rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 0.9rem;
}
.castlePanel .castlePriceDiscounted {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.castlePanel .castleCheckBook {
  background-color: #9cd600;
  background: -webkit-gradient(linear, left top, left bottom, from(#9cd600), to(#60bd00));
  background: linear-gradient(to bottom, #9cd600, #60bd00);
  height: auto;
  border-radius: 5px;
  padding: 10px;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 900 !important;
  color: #fff;
  font-size: 1.2rem;
  text-shadow: 0 2px rgba(0, 0, 0, 0.25);
}
/*#endregion Castle Panels*/
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #ec2028;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 195px;
  display: inline-block;
  margin: 10px 5px;
  float: none;
}
a.BackToCategory {
  width: auto;
  max-width: 320px;
}
.footer__wrapper {
  background-color: #000;
  color: #fff;
  padding: 25px 10px;
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  .footer__wrapper {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.footer__links a {
  margin: 0 5px;
  color: white;
}
.footer__BCN {
  display: block;
  max-width: 180px;
}
@media (max-width: 767px) {
  .footer__BCN {
    text-align: center;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .footer__BCN {
    float: right;
  }
}
.footer__BCN img {
  margin: 0 auto;
}
#BouncyCastleHireLink {
  color: white;
  font-weight: 700;
}
/*#region Utiliy Classes*/
.white {
  color: #fff;
}
.text-shadow-big {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
/*#endregion Utiliy Classes*/
/*#region Animations*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
/*#endregion Animations*/
