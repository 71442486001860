@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1259px)";
@desktop: ~"(min-width: 1260px)";
@mobileNavColor: #ec2028;
@castleDetailsColor: #ec2028;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/001/";
@cloudUrlNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/001/";
/*#region Fonts*/
.OpenSans, .BodyFont { // .BodyFont used in #ToplinksMobile to override .nicefont
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansBold {
	font-weight: 700 !important;
	font-family: 'Open Sans', sans-serif;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700;
}

.LondrindaSolidBlack {
	font-family: 'Londrina Solid', sans-serif;
	font-weight: 900 !important;
}

.font-HvdComicSerifPro;

.HvdComicSerifPro {
	font-family: 'Hvd_comic_serif_pro',sans-serif;
	font-weight: normal !important;
	font-display: swap;
}

body, html {
	font-size: 16px;
}
/*#endregion Fonts*/
#BodyContent {
}

.textContent h1, .lower-content h2 {
	.LondrindaSolidBlack;
	font-size: 2.6rem;
	color: #ff5400;
	text-shadow: none;
	margin-top: 0;
}

.textContent {
	padding: 0;

	@media @mobile {
		text-align: center;
	}
}

.nicefont, h1, h2, h3 {
	.OpenSansBold;
}

form {
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}

body {
	.BodyFont;
	font-size: 16px;
	position: relative;
	background: #33abff;
	position: relative;

	&:before {
		.StickToEdges;
		background-repeat: repeat;
		background-image: url("@{cloudUrl}geometric-pattern-10.png");
		mix-blend-mode: luminosity;
		opacity: 0.1;
	}
}



.outer-wrapper {
	overflow: hidden;
	max-width: 100vw;
	position: relative;
	text-align: center;

	&.outer-wrapper--alt {
		.columns__main {
			background: #fff;

			&:before {
				display: none;
			}
		}

		.textContent {
			padding-top: 20px;
		}

		.curve {
			display: none;
		}

		.columns__main .pageContent {
			//margin-left: -15px;
			//margin-right: -15px;
		}

		.homepage-columns__left {
			width: 100%;
		}
	}

	&.outer-wrapper--category {
	}
}

.container {
	position: relative;

	@media @mobile {
		margin: 0 auto 0;
		padding: 0;
	}

	@media @tablet {
		width: 100%;
		padding: 0;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1230px;
		padding: 0;
		margin: 0 auto 0;
	}

	&__border {
		display: inline-block;
		position: relative;
		padding: 0 10px;


		@media @mobile {
			padding: 0;
		}

		@media @md {
			padding: 0 20px;
			box-shadow: 0px 0px 60px 0px rgba(49, 59, 73, 0.5);
		}

		&:before, &:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			width: 10px;
			background-size: 100% auto;

			@media @md {
				width: 20px;
			}

			@media @mobile {
				display: none;
			}
		}

		&:before {
			left: 0;
			.HorizontalGradient(#006aff,#0062eb);
		}

		&:after {
			right: 0;
			.HorizontalGradient(#0062eb,#006aff);
		}
	}
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
	padding-top: 53px;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();
	min-height: 5px;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	@media @sm {
		display: block !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.drop-shadow {
	filter: drop-shadow(0 2px 4px rgba(0,0,0,0.3));
}

.header {
	@width: 1860px;
	@height: 313px;
	text-align: center;
	position: relative;
	margin-left: -15px;
	margin-right: -15px;

	@media @mobile {
		padding: 30px 15px;
	}

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__bg {
		.Gradient(#0091e8,#43d3f6);
		position: relative;
		padding: 0 15px 1px;
		overflow: hidden;

		&:before {
			.StickToEdges;
			background-image: url("@{cloudUrlNoAuto}starburst.svg");
			background-repeat: repeat;
			opacity: 0.2;
			transform: scale(1.6);
		}
	}

	&__logo-xs {
		max-width: 90% !important;
		display: block;
		margin: 10px auto 0;
	}

	&__follow {
		.TopBarItem(384,@width,@height,auto,217,left,687);

		@media @mobile {
			margin: 10px auto 0;
		}
	}

	&__social {
		.TopBarItem(533,@width,@height,auto,68,left,612);

		@media @mobile {
			position: relative;
			margin: 0 auto 10px;
		}

		@media @tablet {
		}
	}

	#SocialMediaLinks {
		text-align: center;

		@media @mobile {
			text-align: center;
			margin: 0 auto 10px;
			padding: 0 30px;
		}

		a {
			display: inline-block;
			max-width: 20%;
			margin: 2%;

			@media @mobile {
				max-width: 15%;
			}
		}

		img {
			border: 3px solid #fff;
			border-radius: 50%;
			box-sizing: content-box;
		}
	}

	&__call {
		.TopBarItem(562,@width,@height,auto,217,right,59);

		@media @mobile {
			position: relative;
			display: block;
			margin: 20px auto 0;
		}
	}

	&__phone {
		.TopBarItem(605,@width,@height,auto,111,right,38);

		@media @mobile {
			position: relative;
			display: block;
			margin: 0 auto 10px;
		}
	}

	&__email {
		.TopBarItem(564,@width,@height,auto,45,right,58);

		@media @mobile {
			position: relative;
			display: block;
			margin: 10px auto 0;
		}
	}

	&__weather-link {
		.TopBarItem(460,@width,@height,auto,42,left,62);

		@media @mobile {
			position: relative;
			display: flex;
			width: 80%;
			padding: 5px;
			justify-content: space-around;
			max-width: 270px !important;
		}

		@media @tablet {
			height: round(percentage(200/@height),2);
		}

		border-radius: 15px;
		.Gradient(#5fe0f4,#0079d0);

		&:before {
			content: "";
			position: absolute;
			top: 3px;
			left: 3px;
			bottom: 3px;
			right: 3px;
			border-radius: 13px;
			.Gradient(#43cbed,#009bdc);
		}

		&:hover {
			.header__weather-sun {
				transform: scale(1.1);
			}
		}
	}

	&__weather-sun {
		transition: all 0.6s;

		@media @mobile {
			align-self: center;
			z-index: 1;
		}

		@media @tablet {
			position: absolute;
			width: 44%;
			left: -4%;
			top: -6%;
		}
	}

	&__weather-text {
		@media @mobile {
			align-self: center;
			z-index: 1;
		}

		@media @tablet {
			position: absolute;
			width: 51%;
			left: 42%;
			top: 13%;
		}
	}

	&__button {
		background: linear-gradient(to bottom, #c7e800, #329c00);
		color: #FFF;
		font-family: 'Londrina Solid', sans-serif;
		font-weight: 900 !important;
		text-transform: uppercase;
		padding: 10px 30px;
		font-size: 2rem;
		border-radius: 15px;
		text-shadow: -1px 4px 0 rgba(0,0,0,0.3);
		position: relative;
		overflow-wrap: break-word;
		white-space: normal;
		display: block;
		margin: auto;
		width: fit-content;
		.FlexCenter;

		&:hover, &.active, &:focus {
			color: #FFF;
		}

		&-wrapper {
			margin: 20px auto;
		}
	}
}

.banner {
	background-color: #fff;
	position: relative;
	border: 6px solid #ffffff;
	border-radius: 5px;
	.BannerPanelSet(55.18%);
	box-shadow: 0 23px 15px -10px rgba(0,0,0,0.3);
	@width: 2402px;
	@rightWidth: 660px;
	@height: 880px;
}

.widgets {
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 0 15px;
	margin-bottom: 170px;
	flex-direction: column;
	align-items: center;
	@width: 1742px;
	@height: 240px;

	@media @lg {
		flex-direction: row;
	}

	&__outer {
		padding-top: 1px;
		padding-bottom: 1px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: -1px;
			left: -15px;
			right: -15px;
			background-image: url("@{cloudUrl}widgets-bg.png");
			background-size: 100% auto;
			background-position: center bottom;
			background-repeat: no-repeat;
		}
	}

	&__area {
		.Gradient(#c7e800,#329c00);
		border-radius: 15px;
		position: relative;
		margin-bottom: 20px;

		@media @mobile {
			width: 100%;
			max-width: 300px;
		}

		@media @tablet {
			width: 50%;
		}

		@media @lg {
			width: round(percentage(540/@width),2);
			margin-bottom: 0;
		}

		&:before {
			content: "";
			width: 100%;
			display: block;
			padding-top: 66.44%;

			@media @md {
				padding-top: 44.44%;
			}
		}

		&:after {
			content: "";
			position: absolute;
			top: 3px;
			right: 3px;
			bottom: 3px;
			left: 3px;
			border-radius: 13px;
			.Gradient(#a2d900,#4bb400);
		}
	}

	&__area-text {
		position: absolute;
		width: 63.79%;
		left: round(percentage(115/540),2);
		z-index: 1;
		top: 9.92%;

		@media @md {
			top: round(percentage(31/@height),2);
		}
	}

	&__area-icon {
		position: absolute;
		width: 40%;
		left: -10%;
		top: 8%;
		z-index: 1;
	}

	#PostcodeSearchLeft {
		position: absolute;
		height: unset;
		top: 31.5%;
		width: round(percentage(460/540),2);
		left: round(percentage(36/540),2);
		z-index: 1;

		@media @md {
			height: round(percentage(82/@height),2);
			top: round(percentage(114/@height),2);
		}

		.form-control {
			height: 45px;
			padding-left: 27%;
			border: none;
			border-radius: 8px;
			box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

			&::placeholder {
				color: #757575;
			}

			@media @md {
				height: 100%;
			}
		}
	}

	#PostcodeSearchRight {
		position: absolute;
		height: unset;
		top: 64.5%;
		left: 0;
		right: 0;
		text-align: center;

		@media @md {
			display: none;
		}

		.AreaSearchButton {
			position: relative;
			z-index: 2;
			background: linear-gradient(to bottom, #5fe0f4, #0079d0);
			color: #FFF;
			font-family: 'Londrina Solid', sans-serif;
			font-weight: 900 !important;
			text-transform: uppercase;
			width: 60%;
			padding: 5px 30px;
			font-size: 1.75rem;
			border-radius: 15px;
			text-shadow: -1px 4px 0 rgba(0,0,0,0.3);
			position: relative;

			&:hover, &.active, &:focus {
				color: #FFF;
			}
		}
	}

	&__availability {
		.Gradient(#c7e800,#329c00);
		border-radius: 15px;
		position: relative;
		width: 96%;

		@media @mobile {
			max-width: 300px;
			padding: 15px;
		}

		@media @lg {
			width: round(percentage(1180/@width),2);
		}

		&:before {
			content: "";
			width: 100%;
			display: block;

			@media @tablet {
				padding-top: 20.34%;
			}
		}

		&:after {
			content: "";
			position: absolute;
			top: 3px;
			right: 3px;
			bottom: 3px;
			left: 3px;
			border-radius: 13px;
			.Gradient(#a2d900,#4bb400);
		}
	}

	&__availability-text {
		z-index: 1;
		position: relative;

		@media @mobile {
			margin-bottom: 20px;
		}

		@media @tablet {
			position: absolute;
			width: 72.98%;
			left: round(percentage(143/1180),2);
			top: round(percentage(31/@height),2);
		}
	}

	.SearchDate {
		left: round(percentage(36/1180),2);
		width: round(percentage(400/1180),2);
		border: none;
		border-radius: 8px;
		text-align: center;
		color: #757575;

		&::placeholder {
			color: #757575;
		}
	}

	.SearchCategory {
		left: round(percentage(456/1180),2);
		width: round(percentage(400/1180),2);
		border: none;
		border-radius: 8px;
		text-align: center;
		color: #757575;

		&::placeholder {
			color: #757575;
		}
	}

	.SearchButton {
		left: round(percentage(876/1180),2);
		width: round(percentage(280/1180),2);
		border: none;
		border-radius: 8px;

		@media @mobile {
			display: block;
			margin: 0 auto;
		}

		position: relative;
		z-index: 2;
		background: linear-gradient(to bottom, #5fe0f4, #0079d0);
		color: #FFF;
		font-family: 'Londrina Solid', sans-serif;
		font-weight: 900 !important;
		text-transform: uppercase;
		padding: 0 5px 5px;
		font-size: 1.75rem;
		border-radius: 15px;
		text-shadow: -1px 4px 0 rgba(0,0,0,0.3);

		@media @sm {
			font-size: 1.5rem;
		}

		&:hover, &.active, &:focus {
			color: #FFF;
		}
	}

	.SearchDate, .SearchCategory, .SearchButton {
		position: absolute;
		height: round(percentage(82/@height),2);
		top: round(percentage(114/@height),2);
		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
		z-index: 1;

		@media @mobile {
			position: relative;
			width: 80%;
			height: 40px;
			left: auto;
			top: auto;
			margin-bottom: 15px;
		}
	}
}

.featured-cats {
	position: relative;
	text-align: center;
	background: #fff;
	margin-left: -15px !important;
	margin-right: -15px !important;

	&__inner {
		margin-top: -100px;

		& > div {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}

	&__title {
		text-align: center;
		text-transform: uppercase;
		color: #5a5a5a;
		.HvdComicSerifPro;
		font-size: 1.2rem;
	}

	&--web-starter {
		margin-top: 120px;
	}

	&--multiple {
		// this %age value may need overriding on each site theme but will at least stop the page jumping for now.
		.BannerPanelSet(100%);

		.item {
			height: 100%;
		}

		a, img {
			width: 100%;
			height: 100% !important;
			object-fit: contain;
			display: block;
		}
	}
}

.homepage-columns {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&__left {
		width: 100%;

		@media @lg {
			width: 66.67%;
		}

		background: #fff;
	}

	&__right {
		background: #fff;
		padding: 0 15px;
		width: 100%;

		@media @lg {
			width: 33.33%;
		}
	}
}

.search-box {
	background: #fff;
	border-radius: 8px;
	width: ~'calc(100% - 10px)';

	.input-group {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;

		.input-group-btn .btn-default {
			width: 40px;
			.border-left-radius(8px);
			border: none;
			color: #444 !important;
			transition: all 0.3s;

			&:hover {
				color: #000 !important;
				background: #fff !important;
			}
		}

		input {
			border-bottom-right-radius: 8px !important;
			border-top-right-radius: 8px !important;
			border: none;
			width: ~'calc(100% - 40px)';
			height: 40px;
		}
	}
}

.columns {
	display: flex;
	border-radius: 15px;
	position: relative;

	&__row {
		display: flex;
	}

	&__left {
		position: relative;
		width: 200px;
		background: #f2f3ff;
		padding: 0 15px;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHkAAAA9CAMAAAC+7TYBAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAAhFBMVEWjy1Sw21er1VakzFOx3FelzVSmzlSkzFSjy1Oky1Sp0lWv2leq01Wt11as1lamz1Sr1Faky1Ow2lelzFSo0VWnz1Sq1FWs1Vamz1Wo0FSn0FWu2Fao0FWn0FSu2Venz1Wu2Vav2Vaq1Fat1lalzlSr1VWp0VWt2Fav2Vex21er1FWp01WbV8XYAAACx0lEQVRYw8XZ13ajMBAGYIFQoVf3FpckW97//VYyBgORjIp1dq5sx1+GA9LAfwwAACEhBOF8gRFiL5LKC6pjgnjlh9MaUfCibOxD55HvF8XyUnx7QeBV6eenzytaIEod2buGt4UfMzYpLy7mOxvb9rhrtEmuE18ds+SACHVlW11SWEdpPNLHrIGlwlGb2laHtIS4Waa/evuRJRvMMHVmHxqw71Ecpf1lWm0RdWs7zXmN/d+dLthVmsc2ttecg6RbKF6ic9RGdqgR9u/7gf8LH9danfXtULfXKt7xdZpGWKuzvh3qTXYOzumfZnk9s/W50eqsb4c6Yucq/cIUN1d23iKtzvr2uTMIWla7VYQhhTha7aolX6EO7VOj7crzF/edSFHue/4JKXc2sUMdXfYIMkEpRPvia63TWd8O9An354h7rNPZwD7nEEF8+ne6ZDde4tQ+1ycYTR6qNf9M7FgPPpbo8cc2dqCVStLZwOrq8RW0sZo6RGv0JqulaUj2xZZto3dYHc2n5CVO2PMPfYPV03wyFoOZbGM19T4LVhiZdR5bttAVNO0q/5sGWXPr35tbtc49pgd/F6RJrtFZZgHiUWxOE/atmg1mCKPvmD1xNBDyPGVlAU98c5q+SIvGFvDEN6P5qpSmRWMLWOK7zWtpWjS3gCW+evaMEWlaNLeAJb5SYX3K0qK5BaXazpClRXPL/qA0h2Rp0dzyO4fSBJSkRRurOnvFadHGqmtRWrSx6lqUFm2suhalRRurrkVp0cIq7wxxWjS36p3FadHcgvumDBW2pCQtGlugFoCM06Lc8mMm9weLuapvPC4+3pSkZjd7KwtI+1hDVAoKXhtbrc6iMrbCztCis7IVXGee90PVMrcqiU+jdO3wCASJz5kdzSFR4nNlp/pn4nNlf+hJWnRnu98iZGnRnZ3qaVqkzmyrX6RFZ/Z//S75DxVlePHxQA4LAAAAAElFTkSuQmCC');

		@media @md {
			width: 300px;
		}
	}

	&__main {
		position: relative;
		background: #1ad9ff;

		@media @tablet {
			padding: 0 15px;
			width: ~'calc(100% - 200px)';
		}

		@media @md {
			width: ~'calc(100% - 300px)';
		}

		&:before {
			.StickToEdges;
			background-repeat: repeat;
			// background-size: 25px;
			opacity: 0.05;
			background-image: url("@{cloudUrl}main-bg-pattern.png");
		}

		&--offset {

			@media @tablet {
				margin-left: -15px;
				margin-right: -15px;
			}

			position: relative;
			margin-bottom: 0;
		}
	}

	.pageContent {
		background: #fff;
	}
}

.toplinks, .category-links {
	text-align: left;

	&__ul {
		display: contents;
	}

	&__li {
		list-style-type: none;
		position: relative;
	}

	&__link, &__child-link {
		color: #fff;
		background: none;
		font-size: 1.2rem;
		display: block;
		padding: 10px 10px 10px 20px;
		margin-top: 10px;
		position: relative;
		.PTSansNarrowBold;
		transition: all 0.25s;
		.TextShadow1;
		margin-left: -25px;
		background-size: 100% 100%;

		&.active, &:focus {
			color: #fff;
		}

		&:before {
			content: "";
			.TransformMiddleV;
			width: 0;
			height: 0;
			border-top: 5px solid transparent;
			border-left: 10px solid #fff;
			border-bottom: 5px solid transparent;
			left: 10px;
			opacity: 0;
			transition: all 0.15s;
		}

		&:after {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 0;
			width: 0;
			height: 0;
			@color: #000;
			border-top: 5px solid @color;
			border-right: 5px solid @color;
			border-left: 5px solid transparent;
			border-bottom: 5px solid transparent;
		}

		&:hover, &.active {
			padding-left: 30px;
			color: #fff;

			&:before {
				opacity: 1;
				transition: all 0.3s 0.15s;
			}
		}
	}

	&__child-link {
		margin-left: 0;

		&:after {
			display: none;
		}
	}

	&__dropdown {
		right: 0;
		background: linear-gradient(to bottom, #40C1FF, #2495FE);
		margin-left: -25px;

		&-link {
			.TextShadow1;
			.PTSansNarrowBold;
			padding: 10px 10px 10px 20px;
			font-size: 1.2rem;
			color: #fff !important;
			white-space: normal !important;
		}

		&-li {

			&:nth-child(5n+1) {
				.toplinks__dropdown-link {
					&:hover {
						background-color: #F1A639;
						background: linear-gradient(to bottom, #F1A639, #E66921);
					}
				}
			}

			&:nth-child(5n+2) {
				.toplinks__dropdown-link {
					&:hover {
						background-color: #F84BA8;
						background: linear-gradient(to bottom, #F84BA8, #EE2F69);
					}
				}
			}

			&:nth-child(5n+3) {
				.toplinks__dropdown-link {
					&:hover {
						background-color: #C573C5;
						background: linear-gradient(to bottom, #C573C5, #9B479E);
					}
				}
			}

			&:nth-child(5n+4) {
				.toplinks__dropdown-link {
					&:hover {
						background-color: #5FCF5C;
						background: linear-gradient(to bottom, #5FCF5C, #3CAE3A);
					}
				}
			}

			&:nth-child(5n+5) {
				.toplinks__dropdown-link {
					&:hover {
						background-color: #FFD001;
						background: linear-gradient(to bottom, #FFD001, #FFB202);
					}
				}
			}
		}
	}
}

.toplinks {
	&__link {
		background-image: url("@{cloudUrl}gradient-button-blue.svg");

		&:after {
			@color: #175a99;
			border-top: 5px solid @color;
			border-right: 5px solid @color;
		}
	}
}

.category-links {
	&__link {
		background-image: url("@{cloudUrl}gradient-button-orange.svg");

		&:after {
			@color: #a14516;
			border-top: 5px solid @color;
			border-right: 5px solid @color;
		}

		& + .category-links__child-links .category-links__child-link {
			background-image: url("@{cloudUrl}gradient-button-orange.svg");
		}


		&:nth-of-type(5n +2) {
			background-image: url("@{cloudUrl}gradient-button-pink.svg");

			&:after {
				@color: #a61d45;
				border-top: 5px solid @color;
				border-right: 5px solid @color;
			}

			& + .category-links__child-links .category-links__child-link {
				background-image: url("@{cloudUrl}gradient-button-pink.svg");
			}
		}

		&:nth-of-type(5n +3) {
			background-image: url("@{cloudUrl}gradient-button-purple.svg");

			&:after {
				@color: #692f6b;
				border-top: 5px solid @color;
				border-right: 5px solid @color;
			}

			& + .category-links__child-links .category-links__child-link {
				background-image: url("@{cloudUrl}gradient-button-purple.svg");
			}
		}

		&:nth-of-type(5n +4) {
			background-image: url("@{cloudUrl}gradient-button-green.svg");

			&:after {
				@color: #267725;
				border-top: 5px solid @color;
				border-right: 5px solid @color;
			}

			& + .category-links__child-links .category-links__child-link {
				background-image: url("@{cloudUrl}gradient-button-green.svg");
			}
		}

		&:nth-of-type(5n +5) {
			background-image: url("@{cloudUrl}gradient-button-yellow.svg");

			&:after {
				@color: #b27900;
				border-top: 5px solid @color;
				border-right: 5px solid @color;
			}

			& + .category-links__child-links .category-links__child-link {
				background-image: url("@{cloudUrl}gradient-button-yellow.svg");
			}
		}
	}
}

.left-social-intro {
	max-width: 247px !important;
}

.testimonials {

	&__title {
		max-width: 214px !important;
	}

	&__panels {
		@media @tablet {
			display: flex;
			justify-content: space-between;
		}

		@media @lg {
			display: block;
		}
	}

	&__panel {
		padding: 5px;
		margin-bottom: 20px;
		box-shadow: 0 0 0 1px #fff,inset 0 0 0 3px #fff,inset 0 0 0 6px #9dd700;
		border-radius: 10px;
		border: 1px solid #cdcdcd;

		@media @tablet {
			width: 48%;
		}

		@media @lg {
			width: 100%;
		}
	}

	&__carousel-inner {
		border-radius: 10px;
	}

	&__link {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__item {
		padding: 15px;
	}

	&__text {
		color: #808080;
	}

	&__author {
		color: #ff5500;
		font-size: 1.2rem;
		.LondrindaSolidBlack;
	}
}
/*#region Other Left Column Stuff*/
.logo-top, .logo-bottom {
	display: block;
	width: ~'calc(100% + 30px)';
	position: relative;
	left: -15px;
	background-size: 100% 100%;

	&__logo {
		z-index: 1;
		margin: 0 auto;
	}
}

.logo-top {
	//edit fill / gradient in svg
	background-image: url("@{cloudUrl}logo-top-bg.svg");

	&__logo {
		display: none;
	}
}

.logo-bottom {
	//edit fill / gradient in svg
	background-image: url("@{cloudUrl}logo-bottom-bg.svg");
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;

	&__spacer {
		padding-top: 220%;
	}
}

.left-logos {
	max-width: 269px;
	position: relative;

	&:before {
		content: "";
		display: block;
		padding-top: 27.5093%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

#SocialMediaLinks {
	text-align: center;

	@media @tablet {
		text-align: right;
	}

	a {
		display: inline-block;
		max-width: 40%;
		margin: 2%;
	}
}
/*#endregion Other Left Column Stuff*/
.curve {
	position: relative;
	margin: -20px 0 0;


	@media @mobile {
	}

	@media @tablet {
		max-width: none !important;
		width: ~'calc(100% + 30px)';
		left: -15px;
	}
}

.pageContent {
	padding: 20px;

	@media @mobile {
		padding: 10px;
	}
}
/*#region Castle Panels*/
.page-listings {
	background: transparent !important;
	padding: 0 15px;

	@media @mobile {
		padding: 0 10px;
	}

	&__intro {
		position: relative;
		max-width: 524px;
		margin: 0 auto;

		&:before {
			content: "";
			display: block;
			padding-top: 10.8779%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.row-less-padding {
		margin-left: -8px !important;
		margin-right: -8px !important;

		& > div {
			padding-left: 8px !important;
			padding-right: 8px !important;

			@media (max-width:420px) {
				width: 100%;
			}
		}
	}
}

.category-title {
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
	border-radius: 10px;
	background: linear-gradient(to bottom, #bee400 0%,#bee400 40%,#88ce00 60%,#88ce00 100%);
	padding: 15px;
	.LondrindaSolidBlack;
	position: relative;
}

.castlePanel {
	width: 100%;
	border: none;
	padding: 10px;
	background-color: white;
	border-radius: 5px;
	margin: 0 0 30px;
	text-align: center;
	transition: all 0.3s;
	position: relative;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.3);

	@media @mobile {
		text-align: center;
	}

	&:hover {
		.castleCheckBook {
			box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);
		}

		box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);
	}

	.SingleImagePanel {
	}

	.castleTitlePanel {
		margin-top: 10px;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			color: #404040;
			.OpenSansBold;
			position: relative;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #404040;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		.OpenSansBold;
		font-size: 0.9rem;

		span.oldprice {
			color: #ff0000;
			font-size: 0.8rem;
		}

		span.newprice {
			font-size: 0.9rem;
		}
	}

	.castlePriceDiscounted {
		flex-direction: column;
	}

	.castleCheckBook {
		.Gradient(#9cd600,#60bd00);
		height: auto;
		border-radius: 5px;
		padding: 10px;
		box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
		.LondrindaSolidBlack;
		color: #fff;
		font-size: 1.2rem;
		text-shadow: 0 2px rgba(0,0,0,0.25);
	}
}
/*#endregion Castle Panels*/
.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 195px;
		display: inline-block;
		margin: 10px 5px;
		float: none;
	}
}

a.BackToCategory {
	width: auto;
	max-width: 320px;
}

.footer {
	&__wrapper {
		background-color: #000;
		color: #fff;
		padding: 25px 10px;
		width: 100%;
		z-index: 1;
		position: relative;

		@media @mobile {
			text-align: center;
		}
	}

	&__flex-row {
		@media @tablet {
			display: flex;
			align-items: center;
		}
	}

	&__links {
		a {
			margin: 0 5px;
			color: white;
		}
	}

	&__BCN {
		display: block;
		max-width: 180px;

		@media @mobile {
			text-align: center;
			display: inline-block;
		}

		@media @sm {
			float: right;
		}

		img {
			margin: 0 auto;
		}
	}
}

#BouncyCastleHireLink {
	color: white;
	font-weight: 700;
}
/*#region Utiliy Classes*/
.white {
	color: #fff;
}

.text-shadow-big {
	text-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}
/*#endregion Utiliy Classes*/
/*#region Animations*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

/*#endregion Animations*/
